// scss-docs-start breadcrumb-variables
$breadcrumb-font-size: 10px;
$breadcrumb-padding-y: 15px;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding-x: 0.5rem !default;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: null !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $gray-600 !default;
$breadcrumb-divider: quote("/") !default;
$breadcrumb-divider-flipped: $breadcrumb-divider !default;
$breadcrumb-border-radius: null !default;
// scss-docs-end breadcrumb-variables

.breadcrumb {
  a {
    color: $secondary;
  }
}
