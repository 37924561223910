.hero {
  width: 100%;
  height: 75vh;
  position: relative;
  @include media-breakpoint-up(sm) {
    height: 100vh;
  }
  .bg {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    img {
      width: 100%;
      height: 75vh;
      object-fit: cover;
      @include media-breakpoint-up(sm) {
        height: 100vh;
      }
    }
    .carousel {
      position: relative;
      z-index: 1;
    }
    &::after {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.3);
      content: "";
    }
  }
  .cont {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 6vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .catch {
      color: $white;
      line-height: 1.75;
      font-size: 27px;
      br {
        display: none;
      }
      @include media-breakpoint-up(sm) {
        font-size: 36px;
        br {
          display: inline;
        }
      }
      @include media-breakpoint-up(lg) {
        letter-spacing: 0.25em;
        font-size: 39px;
      }
    }
  }
  .topNews {
    position: absolute;
    bottom: 60px;
    left: 6vw;
    width: calc(100% - 12vw);
    z-index: 3;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    font-size: 13px;
    @include media-breakpoint-up(sm) {
      left: 45px;
      width: calc(100% - 90px);
    }
    @media (min-width: 900px) {
      left: calc((100vw - 900px) / 2);
      width: 900px;
      font-size: 15px;
    }
    .header {
      width: 90px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1px;
      background-color: rgba($primary, 0.75);
      @media (min-width: 900px) {
        width: 150px;
      }
    }
    .body {
      flex: 1;
      height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 15px;
      background-color: rgba($primary, 0.75);
      @include media-breakpoint-up(sm) {
        padding: 0 30px;
      }
      a {
        color: $white;
        text-decoration: none;
        transition: $transition-base;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
        @include media-breakpoint-up(sm) {
          flex-direction: row;
        }
        &:hover {
          text-decoration: underline;
        }
        .date {
          @include media-breakpoint-up(sm) {
            margin-right: 1rem;
          }
        }
        .title {
        }
      }
    }
  }
  .scrollIcon {
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 30px;
    color: $white;
    text-transform: uppercase;
    font-size: 12px;
    transform: rotate(90deg);
    transform-origin: right bottom;
    opacity: 0.6;
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
    &::after {
      width: 150px;
      height: 1px;
      background-color: $white;
      content: "";
      display: block;
    }
  }
}
