.secondHeader {
  position: relative;
  .img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: 330px;
    }
    @include media-breakpoint-up(lg) {
      height: 390px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0 6%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: $headerHeightSM;
    @include media-breakpoint-up(sm) {
      padding-top: $headerHeightLG;
    }
    .catch {
      color: $white;
      font-size: 27px;
      font-weight: bold;
      line-height: 1.5;
      @include media-breakpoint-up(sm) {
        font-size: 36px;
        line-height: 1.25;
      }
      @include media-breakpoint-up(lg) {
        font-size: 45px;
        letter-spacing: 0.25em;
      }
    }
  }
}
