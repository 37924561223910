.toggleMenu {
  width: 80%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zindex-fixed;
  background-color: $white;
  overflow: auto;
  .header {
    width: 100%;
    height: $headerHeightSM;
    display: flex;
    justify-content: flex-end;
    .toggleMenuBtn {
      grid-area: toggleMenuBtn;
      width: $headerHeightSM;
      height: $headerHeightSM;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      background-color: $primary;
      font-size: 24px;
    }
  }
  > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 13px;
    > li {
      border-top: 1px solid $border-color;
      &:last-child {
        border-bottom: 1px solid $border-color;
      }
      > a {
        display: block;
        padding: 1rem;
        color: $body-color;
        text-decoration: none;
      }
      > ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        position: relative;
        border-top: 1px dotted $border-color;
        background-color: $gray-200;
        &::before {
          position: absolute;
          width: 1px;
          height: 100%;
          background-color: $border-color;
          top: 0;
          left: 1rem;
          content: "";
        }
        > li {
          > a {
            display: block;
            padding: 0.75rem 1rem;
            padding-left: 2rem;
            color: $body-color;
            text-decoration: none;
          }
        }
      }
    }
  }
}
