.sectionHeader {
  padding-top: 30px;
  position: relative;
  margin-bottom: 45px;
  color: $primary;
  @include media-breakpoint-up(sm) {
    padding-top: 45px;
    margin-bottom: 60px;
  }
  &::before {
    content: "";
    width: 70px;
    height: 3px;
    background-color: $primary;
    position: absolute;
    top: 0;
    left: 0;
  }
  .title {
    font-size: 30px;
    font-weight: 900;
    line-height: 1.2;
    margin: 0;
    @include media-breakpoint-up(sm) {
      font-size: 37.5px;
    }
  }
  .title-en {
    font-family: $font-family-en;
    margin: 0;
    margin-top: 1rem;
  }
}
