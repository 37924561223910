.digest {
  .img {
    position: relative;
    width: 100%;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    padding: 24px 6vw;
    background-color: $white;
    .title {
      line-height: 1.5;
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: normal;
      border-bottom: 1px solid $primary;
      color: $primary;
      padding-bottom: 15px;
      @include media-breakpoint-up(lg) {
        font-size: 30px;
      }
    }
    .text {
      line-height: 1.75;
      color: $primary;
      margin-bottom: 20px;
    }
    .moreLink {
      font-size: 13px;
      @include media-breakpoint-up(sm) {
        font-size: 14px;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    padding: 0 6vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .img {
      width: 60%;
      margin-right: -10%;
      position: relative;
      z-index: 1;
    }
    .cont {
      width: 50%;
      position: relative;
      z-index: 2;
      padding: 30px;
      @include media-breakpoint-up(lg) {
        padding: 45px;
      }
    }
    &.reverse {
      flex-direction: row-reverse;
      .img {
        margin-right: 0;
        margin-left: -10%;
      }
    }
  }
}
