.postHeader {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 45px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 60px;
  }
  .title {
    margin: 0;
    padding: 0;
    line-height: 1.2;
    font-size: 27px;
    font-weight: bold;
    @include media-breakpoint-up(sm) {
      font-size: 36px;
    }
  }
  .data {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 12px;
    .post-categories {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        margin-right: 1rem;
        a {
          padding: 5px 20px;
          background-color: $primary;
          color: $white;
          text-decoration: none;
          transition: $transition-base;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background-color: shade-color($primary, 10%);
          }
        }
      }
    }
    .date {
      font-weight: bold;
    }
  }
}
.cms {
  $cms-space: 2.5rem;
  font-size: 15px;
  // padding: 0 1rem;
  max-width: 800px;
  margin: 0 auto;
  word-break: break-all;
  @include media-breakpoint-up(sm) {
    padding: 0;
  }
  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
  a:not([class]) {
    text-decoration: underline;
  }
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: $cms-space;
    font-weight: 900;
  }
  p {
    line-height: 2;
    margin-bottom: $cms-space;
    &.lead {
      font-size: 125%;
      color: $gray-600;
    }
  }
  hr {
    margin: $cms-space * 2 auto;
  }
  ul,
  ol {
    margin-bottom: $cms-space;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .wp-block-image,
  .note-block,
  .wp-block-columns {
    margin-bottom: $cms-space;
  }
}
