#footer {
  background-color: $primary;
  color: $white;
  padding: 60px 6vw;
  display: grid;
  grid-template:
    "logo"
    "..." 20px
    "info"
    "..." 30px
    "copyright"
    / 1fr;
  @include media-breakpoint-up(sm) {
    grid-template:
      "logo"
      "..." 30px
      "gnav"
      "..." 20px
      "info"
      "..." 30px
      "copyright"
      / 1fr;
  }
  @include media-breakpoint-up(lg) {
    grid-template:
      "logo ... gnav"
      ".... ... ...." 30px
      "info ... copyright"
      / auto 1fr auto;
  }
  .logo {
    grid-area: logo;
    svg {
      fill: $white;
      max-height: 50px;
    }
  }
  .info {
    grid-area: info;
    font-size: 12px;
    a {
      color: $white;
    }
  }
  .gnav {
    grid-area: gnav;
    display: none;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 13px;
      li {
        margin-right: 1.5rem;
        a {
          color: $white;
          text-decoration: none;
          transition: $transition-base;
          &:hover {
            color: $white;
            text-decoration: underline;
          }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
      li {
        margin-right: 0;
        margin-left: 1.5rem;
      }
    }
  }
  .copyright {
    grid-area: copyright;
    font-size: 10px;
    opacity: 0.75;
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      height: 100%;
    }
  }
}
