.recruitBnr {
  position: relative;
  display: block;
  overflow: hidden;
  text-decoration: none;
  .bgImg {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      padding-top: calc(360 / 1200 * 100%); //高さの比率 ÷ 幅の比率 × 100
      display: block;
      @include media-breakpoint-down(sm) {
        padding-top: calc(500 / 1200 * 100%); //高さの比率 ÷ 幅の比率 × 100
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .bgObj {
    position: absolute;
    z-index: 2;
    top: 0%;
    left: -50%;
    width: 100%;
    height: 100%;
    background-color: rgba($primary, 0.75);
    transform: skew(-15deg);
    transition: $transition-base;
    mix-blend-mode: multiply;
    @include media-breakpoint-down(sm) {
      left: -40%;
    }
  }
  .cont {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: $white;
    padding: 0 6vw;
    @include media-breakpoint-down(sm) {
      width: 60%;
    }
    .title {
      font-family: $font-family-en;
      font-weight: normal;
      font-size: 5vw;
      line-height: 0.75;
      margin-bottom: 0.25em;
      text-transform: uppercase;
      @include media-breakpoint-down(lg) {
        font-size: 45px;
        margin-bottom: 20px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 36px;
        margin-bottom: 10px;
      }
    }
    .subTitle {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      margin-bottom: 30px;
      &::before {
        margin-right: 5px;
        font-family: "Font Awesome 5 Pro";
        content: "\f054";
        transition: $transition-base;
      }
      @include media-breakpoint-down(lg) {
        font-size: 14px;
        margin-bottom: 20px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 10px;
        margin-bottom: 15px;
      }
    }
    .text {
      font-size: 18px;
      letter-spacing: 0.25em;
      margin: 0;
      @include media-breakpoint-down(lg) {
        font-size: 15px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 10px;
        letter-spacing: 0.15em;
      }
    }
  }
  &:hover {
    .bgObj {
      width: 175%;
    }
    .cont {
      .subTitle {
        &::before {
          transform: rotateX(180deg);
        }
      }
    }
  }
}
