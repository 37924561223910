// Links
//
// Style anchor elements.

$link-color: $primary;
$link-decoration: underline;
$link-shade-percentage: 20%;
$link-hover-color: shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: null;

.moreLink {
  display: flex;
  align-items: baseline;
  color: $secondary;
  text-decoration: none;
  transition: $transition-base;
  &::before {
    margin-right: 5px;
    font-family: "Font Awesome 5 Pro";
    content: "\f054";
    transition: $transition-base;
  }
  &:hover {
    color: $body-color;
    &::before {
      transform: translateX(2px);
    }
  }
}
