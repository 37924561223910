$headerHeightSM: 80px;
$headerHeightLG: 110px;
#header {
  position: fixed;
  z-index: $zindex-fixed;
  top: 0;
  width: 100%;
  transition: $transition-base;
  display: grid;
  grid-template:
    "logo ... toggleMenuBtn" #{$headerHeightSM}
    / auto 1fr #{$headerHeightSM};
  @include media-breakpoint-up(sm) {
    grid-template:
      "logo ... gnav ... hcta" #{$headerHeightLG}
      / auto 1fr auto 30px #{$headerHeightLG};
  }
  .logo {
    grid-area: logo;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;
    @include media-breakpoint-up(sm) {
      padding: 0 2rem;
    }
    svg {
      height: 36px;
      width: auto;
      fill: $white;
      transition: $transition-base;
      @include media-breakpoint-up(sm) {
        height: 40px;
      }
      @include media-breakpoint-up(lg) {
        height: 50px;
      }
    }
  }
  .gnav {
    grid-area: gnav;
    display: none;
    @include media-breakpoint-up(sm) {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 13px;
        li {
          margin-left: 1.5rem;
          a {
            color: $white;
            text-decoration: none;
            transition: $transition-base;
            position: relative;
            display: inline-block;
            &::after {
              position: absolute;
              bottom: -6px;
              left: 0;
              width: 0;
              opacity: 0;
              height: 3px;
              background-color: $white;
              content: "";
              transition: $transition-base;
            }
            &:hover {
              color: $white;
              &::after {
                width: 100%;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .hcta {
    grid-area: hcta;
    display: none;
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      color: $white;
      text-decoration: none;
      transition: $transition-base;
      &:hover {
        background-color: shade-color($primary, 10%);
      }
      .icon {
        font-size: 24px;
      }
      .text {
        margin-top: 5px;
        font-size: 12px;
      }
    }
  }
  .toggleMenuBtn {
    grid-area: toggleMenuBtn;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    color: $white;
    font-size: 24px;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  &.bg {
    background-color: $white;
    .logo {
      svg {
        fill: $body-color;
      }
    }
    .gnav {
      ul {
        li {
          a {
            color: $body-color;
            &::after {
              background-color: $body-color;
            }
            &:hover {
              color: $body-color;
            }
          }
        }
      }
    }
  }
}
