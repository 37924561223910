@import "custom";
@import "~bootstrap/scss/bootstrap";

html {
  font-size: 15px;
  font-feature-settings: "palt";
  letter-spacing: 0.08em;
}
img {
  max-width: 100%;
  height: auto;
}
[v-cloak] {
  display: none;
}

@import "components/header";
@import "components/toggleMenu";
@import "components/hero";
@import "components/secondHeader";
@import "components/sectionHeader";
@import "components/topLead";
@import "components/newsList";
@import "components/brandTable";
@import "components/layout";
@import "components/summary";
@import "components/cms";
@import "components/outline";
@import "components/digest";
@import "components/article";
@import "components/coverBgImg";
@import "components/sideMenu";
@import "components/tagCloud";
@import "components/narrowThumbnailLink";
@import "components/imgList";
@import "components/recruitBnr";
@import "components/formTable";
@import "components/carousels";
@import "components/footer";
@import "components/vif";

.font-en {
  font-family: $font-family-en;
}
